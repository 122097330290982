main .photos {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 10px;
	columns: 250px;
	column-gap: 10px;
}

article {
	margin-bottom: 8px;
	position: relative;

	.title {
		position: absolute;
		bottom: 35px;
		left: 15px;
	}

	.author,
	.author-spinner {
		position: absolute;
		bottom: 15px;
		left: 15px;
	}
}
