@import url('https://fonts.googleapis.com/css2?family=Lato&family=Roboto&display=swap');

$header-font: 'Roboto', serif;
$body-font: 'Lato', sans-serif;

.main-spinner {
	display: block;
	width: 30px;
	margin: 200px auto;
}

article {
	img {
		width: 100%;
		min-height: 100px;
	}

	a {
		text-decoration: none;
		font-family: $body-font;
		color: white;
		visibility: hidden;
	}

	.author-spinner {
		display: none;
		width: 15px;
		min-height: 0;
	}
}

article:hover {
	img {
		filter: brightness(70%);
	}

	a {
		visibility: visible;
	}

	.author-spinner {
		display: block;
	}
}
